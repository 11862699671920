import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const CustomerCase = ({ children, image, slug, title }) => {
  const linkTarget = `${/kundcase/}${slug}`

  return (
    <div className='customer-case'>
      <Link to={linkTarget} className='link'>
        <GatsbyImage
          image={image}
          className='customer-case__image'
          alt='Kundcase-bilder'
        />
        <h3 className='customer-case__title'>{title}</h3>
      </Link>
      <div className='customer-case__content'>{children}</div>
      <p>
        <Link to={linkTarget} className='link'>
          Läs mer om {title} <i className='fal fa-long-arrow-right'></i>
        </Link>
      </p>
    </div>
  )
}

export default CustomerCase
