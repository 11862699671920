import React from 'react'
import { graphql } from 'gatsby'

import CustomerCase from 'components/CustomerCase'
import Layout from 'components/Layout'
import SectionWithColumnLayout from 'components/SectionWithColumnLayout'

const Kundcase = ({ data }) => (
  <Layout
    pageTitle='Kundcase'
    headerBackgroundImage={data.header.childImageSharp.gatsbyImageData}
    headerBackgroundPosition='100% 20%'
  >
    <oma-grid-row contentAlignedWithGrid='true'>
      <SectionWithColumnLayout title='Kundcase'>
        <p className='section__text'>
          Här ser du ett urval av de kunder vi har jobbat med. Läs gärna mer om
          de uppdrag som du tycker låter intressanta.
        </p>
        <CustomerCase
          image={data.agab.childImageSharp.gatsbyImageData}
          slug='agab'
          title='AGAB'
        >
          <p className='section__text'>
            Patrik Eriksson driver sedan 2017 AGAB som utför markentreprenad,
            ofta som underentreprenör till de större företagen, något som blir
            mer och mer vanligt i branschen. AGAB består av Patrik som äger
            bolaget och Marcus Wall som är anställd och jobbar som
            anläggningsarbetare...
          </p>
        </CustomerCase>
        <CustomerCase
          image={data.familjenSandwall.childImageSharp.gatsbyImageData}
          slug='familjen-sandwall'
          title='Familjen Sandwall'
        >
          <p className='section__text'>
            Familjen Sandwall är en skönhetssalong som riktar sig lika mycket
            till män som till kvinnor och har ett brett utbud av tjänster såsom
            frisörer, barberare, nagel-, frans- och brynvård. Dessutom jobbar
            man med peruker och Liza är utbildad peruktekniker. Liza Hermansson
            driver salongen sedan 2012 tillsammans med Janne Larsson, Familjen
            Sandwall mitt i Borås på Sandwalls plats...
          </p>
        </CustomerCase>
        <CustomerCase
          image={data.optimalMarketing.childImageSharp.gatsbyImageData}
          slug='optimal-marketing-ab'
          title='Optimal Marketing AB'
        >
          <p className='section__text'>
            Madeleine Olsen driver sedan 2014 Optimal Marketing AB som erbjuder
            små och medelstora företag tjänster inom marknadsföring- och
            kommunikation. Det kan vara allt från några timmar/månad till att
            jobba under kundens ”egen flagg” som marknadschef...
          </p>
        </CustomerCase>
      </SectionWithColumnLayout>
    </oma-grid-row>
  </Layout>
)

export const query = graphql`
  {
    header: file(relativePath: { eq: "agab3.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    agab: file(relativePath: { eq: "agab.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 70
          transformOptions: { cropFocus: SOUTH }
          layout: FULL_WIDTH
        )
      }
    }
    familjenSandwall: file(relativePath: { eq: "familjen-sandwall.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 70
          transformOptions: { cropFocus: SOUTH }
          layout: FULL_WIDTH
        )
      }
    }
    optimalMarketing: file(relativePath: { eq: "optimal-marketing.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 70
          transformOptions: { cropFocus: CENTER }
          layout: FULL_WIDTH
        )
      }
    }
  }
`

export default Kundcase
